export default (theme) => ({
  heading: {
    fontSize: '48px',
    fontFamily: 'THEOUTNET-Body',
    fontWeight: theme.typography.styles.Heading.B2.bp3.fontWeight,
    textTransform: theme.typography.styles.Heading.A1.bp1.textTransform,
    lineHeight: theme.typography.styles.Heading.A3.bp1.lineHeight
  },
  preTitle: {
    margin: 0,
    fontSize: theme.typography.styles.Heading.B6.bp1.fontSize,
    textTransform: theme.typography.styles.Heading.A1.bp1.textTransform,
    lineHeight: theme.typography.styles.Heading.A3.bp1.lineHeight
  },
  subTitle: {
    fontSize: '20px',
    lineHeight: theme.typography.styles.Paragraph[1].bp1.lineHeight,
    paddingBottom: `${theme.spacingMultiplier}px`,
    letterSpacing: '.95px'
  },
  cta: {
    ...theme.typography.styles.Paragraph['4'].bp1,
    fontWeight: 'normal',
    border: '1px solid rgba(0,0,0,1)',
    borderRadius: '0',
    backgroundColor: 'transparent',
    color: 'rgba(0,0,0,1)',
    display: 'table',
    width: 'auto',
    margin: '0 auto',
    height: '32px',
    lineHeight: `${theme.spacingMultiplier * 6 - 2}px`,
    padding: `0 ${theme.spacingMultiplier * 2}px`,
    marginTop: `${theme.spacingMultiplier * 4}px`,
    transition: 'border 200ms ease 0s, color 100ms ease 0s, background-color 200ms ease 0s',
    textTransform: 'uppercase',
    ':hover': {
      border: '1px solid rgba(0,0,0,1)',
      backgroundColor: 'rgba(0,0,0,1)',
      color: 'rgba(250,250,250,1)'
    }
  },
  ctaHover: {
    position: 'relative',
    '[data-extra-text]:hover': {
      color: 'transparent'
    },
    ':before': {
      content: 'attr(data-extra-text)',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      color: 'transparent'
    },
    ':hover:before': {
      color: 'rgba(250,250,250,1)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
})
